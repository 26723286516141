import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  isNew: boolean;

  constructor(public authService: AuthService, public router: Router) {
    this.authService.isNew$.subscribe((value) => {
      this.isNew = value;
    });
  }

  canActivate(): boolean {
    if (!this.isNew) {
      this.router.navigate(["/"]);
      return false;
    }
    return true;
  }
}
