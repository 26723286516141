import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appSkipLink]",
  standalone: true,
})
export class SkipLinkDirective {

  constructor() {
  }

  @HostListener("click")
  onClick() {
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.tabIndex = -1;
      mainContent.focus();
    }
  }
}
