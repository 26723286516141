<app-skip-link></app-skip-link>
<div [ngClass]="{'shift-content': showSkipLink}">
  <div class="overflow-fix white-bg">
    <app-global-header></app-global-header>
  </div>
  <div class="overflow-fix white-bg">
    <app-fab-button></app-fab-button>
    <div id="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
