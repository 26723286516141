import { Routes } from "@angular/router";
import { AuthGuard } from "../../shared/authguards/auth.guard";

export const APPOINTMENT_ROUTES: Routes = [
  {
    path: "appointment",
    loadComponent: () => import("./appointment.component").then(m => m.AppointmentComponent),
    canActivate: [AuthGuard],
    data: { title: "pageTitles.appointment" },
    children: [
      {
        path: "",
        loadComponent: () => import("./appointment-info/appointment-info.component").then(m => m.AppointmentInfoComponent),
        data: { title: "pageTitles.appointment" },
      },
      {
        path: "visit/review",
        loadComponent: () => import("./appointment-review/appointment-review.component").then(m => m.AppointmentReviewComponent),
        data: { title: "pageTitles.visitReview" },
      },
      {
        path: "examination/review",
        loadComponent: () => import("./appointment-review/appointment-review.component").then(m => m.AppointmentReviewComponent),
        data: { title: "pageTitles.examinationReview" },
      },
      {
        path: "procedure/review",
        loadComponent: () => import("./appointment-review/appointment-review.component").then(m => m.AppointmentReviewComponent),
        data: { title: "pageTitles.procedureReview" },
      },
      {
        path: "info",
        loadComponent: () => import("./appointment-header/appointment-header.component").then(m => m.AppointmentHeaderComponent),
        data: { title: "pageTitles.appointmentInfo" },
      },
      {
        path: "message",
        loadComponent: () => import("../message/message.component").then(m => m.MessageComponent),
        data: { title: "pageTitles.appointmentMessages" },
      },
    ],
  },
];
