import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { NewUserComponent } from "src/app/features/login/new-user/new-user.component";
import { ModalService } from "../../services/modal.service";
import { AuthService } from "../../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class LeaveFormGuard implements CanDeactivate<NewUserComponent> {
  isAuthenticated: boolean;
  isNew: boolean;

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
  ) {
    this.authService.isLoggedIn().subscribe((value) => {
      this.isAuthenticated = value;
    });
    this.authService.isNew$.subscribe((value) => {
      this.isNew = value;
    });
  }

  canDeactivate(
    component: NewUserComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // console.log("nextstate:", nextState.url);

    if (
      !nextState.url.includes("terms") &&
      !nextState.url.includes("settings")
    ) {
      // console.log("terms===", nextState.url.includes("terms"));
      if (!this.isAuthenticated && this.isNew) {
        this.modalService.showAreYouSureModal();
        return false;
      }
      return true;
    } else {
      return true;
    }
  }
}
