import { Routes } from "@angular/router";
import { AuthGuard } from "../../shared/authguards/auth.guard";

export const DISEASE_ROUTES: Routes = [
  {
    path: "diseases",
    loadComponent: () => import("./disease.component").then(m => m.DiseaseComponent),
    canActivate: [AuthGuard],
    data: { title: "pageTitles.diseases" },
    children: [
      {
        path: "",
        loadComponent: () => import("./disease-info/disease-info.component").then(m => m.DiseaseInfoComponent),
        data: { title: "pageTitles.diseases" },
      },
      {
        path: "info",
        loadComponent: () => import("./disease-header/disease-header.component").then(m => m.DiseaseHeaderComponent),
        data: { title: "pageTitles.diseaseInfo" },
      },
      {
        path: "review",
        loadComponent: () => import("./disease-review/disease-review.component").then(m => m.DiseaseReviewComponent),
        data: { title: "pageTitles.diseasesReview" },
      },
      {
        path: "message",
        loadComponent: () => import("../message/message.component").then(m => m.MessageComponent),
        data: { title: "pageTitles.diseasesMessages" },
      },
    ],
  },
];
