<div class="alert-box">
  <p [ngClass]="{'font-1-8': !isMobile}" class="text-center">{{ "healthPlan.dataUnsaved" | translate }}</p>
  <br>
  <div class="d-flex flex-column gap-05">
    <mat-dialog-actions [ngClass]="{'w-50': !isMobile}">
      <button
        (click)="onClose(false)"
        class="btn black-outline"
        mat-dialog-close
        type="button">{{ "shared.noStay" | translate }}
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions [ngClass]="{'w-50': !isMobile}">
      <button
        (click)="onClose(true)"
        [mat-dialog-close]="true"
        class="btn black-outline"
        type="button"
      >{{ "shared.yesLeave" | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>

