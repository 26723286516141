/**
 * Interceptor for tracking progress of POST requests.
 */

import { Injectable } from "@angular/core";
import { HttpEvent, HttpEventType, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { SendingProgressUpdateService } from "../../services/sending-progress-update.service";

@Injectable()
export class SendingProgressInterceptor implements HttpInterceptor {

  constructor(private progressUpdateService: SendingProgressUpdateService) {
  }

  /**
   * Intercepts the outgoing HTTP requests and handles progress tracking for POST requests.
   * @param req The intercepted HttpRequest object.
   * @param next The HttpHandler for the next interceptor or the backend if no more interceptors are registered.
   * @returns An Observable of the HttpEvent stream.
   */

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method.toUpperCase() === "POST") {
      const headers = new HttpHeaders({
        // Add any custom headers if needed
      });
      const clonedReq = req.clone({ headers });
      return next.handle(clonedReq).pipe(
        tap(event => {
          // Calculate the progress percentage
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round((100 * event.loaded) / event.total);
            // Emit the progress update using the progressUpdateService
            this.progressUpdateService.emitProgressUpdate(progress);
          }
        }),
      );
    }
    return next.handle(req);
  }
}
