import { Routes } from "@angular/router";
import { LoginGuard } from "../../shared/authguards/login.guard";

export const BEFORE_LOGIN_ROUTES: Routes = [
  {
    path: "welcome",
    loadComponent: () => import("./before-login.component").then(m => m.BeforeLoginComponent),
    data: { title: "pageTitles.welcome" },
    canActivate: [LoginGuard],
    children: [
      {
        path: "",
        loadComponent: () => import("./landing-page/landing-page.component").then(m => m.LandingPageComponent),
        data: { title: "pageTitles.welcome" },
      },
      {
        path: "about",
        loadComponent: () => import("./about/about.component").then(m => m.AboutComponent),
        data: { title: "pageTitles.about" },
      },
      {
        path: "contact",
        loadComponent: () => import("../settings/contact/contact.component").then(m => m.ContactComponent),
        data: { title: "pageTitles.contact" },
      },
      {
        path: "menu",
        loadComponent: () => import("./menu/menu.component").then(m => m.MenuComponent),
        data: { title: "pageTitles.menu" },
      },
      {
        path: "practitioners",
        loadComponent: () => import("./practitioners/practitioners.component").then(m => m.PractitionersComponent),
        data: { title: "pageTitles.practitioners" },
      },
      {
        path: "menu/install",
        loadComponent: () => import("./menu/menu.component").then(m => m.MenuComponent),
        data: { title: "pageTitles.menuInstall" },
      },
    ],
  },
];
