import { Routes } from "@angular/router";
import { AuthGuard } from "../../shared/authguards/auth.guard";

export const CERTIFICATES_ROUTES: Routes = [
  {
    path: "certificates",
    loadComponent: () => import("./certificates.component").then(m => m.CertificatesComponent),
    canActivate: [AuthGuard],
    data: { title: "pageTitles.certificates" },
    children: [
      {
        path: "",
        loadComponent: () => import("./certificates-info/certificates-info.component").then(m => m.CertificatesInfoComponent),
        data: { title: "pageTitles.certificates" },
      },
      {
        path: "info",
        loadComponent: () => import("./certificates-header/certificates-header.component").then(m => m.CertificatesHeaderComponent),
        data: { title: "pageTitles.certificatesInfo" },
      },
      {
        path: "review",
        loadComponent: () => import("./certificates-review/certificates-review.component").then(m => m.CertificatesReviewComponent),
        data: { title: "pageTitles.certificatesReview" },
      },
      {
        path: "message",
        loadComponent: () => import("../message/message.component").then(m => m.MessageComponent),
        data: { title: "pageTitles.certificatesMessages" },
      },
    ],
  },
];
