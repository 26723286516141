import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { SessionContext } from "../../models/session-context.model";
import { MatDialog } from "@angular/material/dialog";
import { ModalService } from "../../services/modal.service";
import { UserInfoService } from "../../services/user-info.service";
import { GeneralRequestService } from "../../services/general-request.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private dialog: MatDialog,
              private modalService: ModalService,
              private userInfoService: UserInfoService,
              private generalRequestService: GeneralRequestService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        // Only process HttpResponse events
        if (event instanceof HttpResponse) {
          const sessionContextString = sessionStorage.getItem("sessionContext");
          // Ensure session context exists
          if (sessionContextString) {
            this.checkSessionMismatch(event);
          }
        }
      }),
    );
  }

  private checkSessionMismatch(event: HttpResponse<any>) {
    try {
      const responseSessionId = event.headers.get("Egp-Session-Id");

      const sessionContext: SessionContext = this.userInfoService.getSessionContext();
      const storedSessionId = sessionContext?.sessionId;

      // Handle session ID mismatch if both IDs are valid
      if (responseSessionId && storedSessionId && responseSessionId !== storedSessionId) {
        console.warn("Session ID mismatch detected:", { responseSessionId, storedSessionId });

        if (!this.isModalAlreadyOpen()) {
          this.modalService.showSessionExpiredModal();
          this.generalRequestService.sessionMismatchDetected = true;
        }
      }
    } catch (error) {
      console.error("Failed to parse sessionContext from sessionStorage", error);
    }
  }

  private isModalAlreadyOpen(): boolean {
    return this.dialog.openDialogs.length > 0;
  }
}

