import { Routes } from "@angular/router";
import { LoginGuard } from "../../shared/authguards/login.guard";

export const GLOBAL_CONTENT_ROUTES: Routes = [
  {
    path: "",
    loadComponent: () => import("./global-content.component").then(m => m.GlobalContentComponent),
    canActivate: [LoginGuard],
    children: [
      {
        path: "info",
        loadComponent: () => import("./info/info.component").then(m => m.InfoComponent),
        data: { title: "pageTitles.info" },
      },
      {
        path: "emo",
        loadComponent: () => import("./info/emo/emo.component").then(m => m.EmoComponent),
        data: { title: "pageTitles.emo" },
      },
      {
        path: "video-guides",
        loadComponent: () => import("./info/video-guides/video-guides.component").then(m => m.VideoGuidesComponent),
        data: { title: "pageTitles.videos" },
      },
      {
        path: "terms",
        loadComponent: () => import("./terms/terms.component").then(m => m.TermsComponent),
        data: { title: "pageTitles.terms" },
      },
      {
        path: "privacy",
        loadComponent: () => import("./terms/terms.component").then(m => m.TermsComponent),
        data: { title: "pageTitles.privacy" },
      },
      {
        path: "price-list",
        loadComponent: () => import("./price-list/price-list.component").then(m => m.PriceListComponent),
        data: { title: "pageTitles.priceList" },
      },
    ],
  },
];
