import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { FabButtonService } from "./fab-button.service";
import { Location, NgIf, NgClass, CommonModule } from "@angular/common";
import { OverlayService } from "./overlay.service";
import { DeviceDetectService } from "../../services/device-detect.service";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "../tooltip/tooltip.component";
import { TooltipModule } from "@amin-karimi/ng2-tooltip-directive";
import { MatIcon } from "@angular/material/icon";
import { MatFabButton } from "@angular/material/button";


@Component({
  selector: "app-fab-button",
  templateUrl: "./fab-button.component.html",
  styleUrls: ["./fab-button.component.css"],
  standalone: true,
  imports: [NgIf, MatFabButton, NgClass, MatIcon, TooltipModule, TooltipComponent, TranslateModule, CommonModule],
})
export class FabButtonComponent implements OnInit, OnDestroy {

  @Input() button: string;
  isVisible = true;
  isHealthPlan: any;
  isDiary: any;
  atLocation: NavigationStart;
  isSubMenuOpen = false;
  isActivityReport = false;
  isRoot: boolean;
  hasActivePlan: boolean;
  private subs = [];
  isMobile: boolean;

  constructor(private router: Router,
              private location: Location,
              private overlay: OverlayService,
              private fabButtonService: FabButtonService,
              private deviceDetect: DeviceDetectService) {
  }

  ngOnInit(): void {
    this.isMobile = this.deviceDetect.isMobile;
    let shouldShowSub = this.fabButtonService.shouldShowFAB.subscribe(data => this.isVisible = data);
    let subMenuSub = this.fabButtonService.isSubMenuOpen.subscribe(data => this.isSubMenuOpen = data);
    let hasActivePlanSub = this.fabButtonService.hasActiveHealthPlan.subscribe(data => this.hasActivePlan = data);

    let atLocationSub = this.fabButtonService.atLocation.subscribe((data) => {
      this.atLocation = data;
      this.isHealthPlan = false;
      this.isDiary = false;
      this.isRoot = false;
      this.isActivityReport = false;
      this.getCurrentLocationUrl();
    });
    this.subs.push(...[shouldShowSub, subMenuSub, hasActivePlanSub, atLocationSub]);
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  goToLocation(url: string) {
    this.router.navigate([this.location.path() + url]);
  }

  openSubMenu() {
    this.fabButtonService.isSubMenuOpen.next(true);
    this.overlay.open();
  }

  private getCurrentLocationUrl() {
    if (this.atLocation.url.includes("health-plan")) {
      this.isHealthPlan = true;
    } else if (this.atLocation.url.includes("diary")) {
      this.isDiary = true;
    } else if (this.atLocation.url == "/") {
      this.isRoot = true;
    }
    if (this.atLocation.url.includes("/health-plan/new")) {
      this.isActivityReport = true;
    } else {
      return;
    }
  }

  // when user is done adding new activity report
  saveAndGoBack() {
    // we actually don't save, everything was already saved on each action, we just go back
    this.location.back();
  }
}
