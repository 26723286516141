import { Routes } from "@angular/router";
import { AuthGuard } from "../../shared/authguards/auth.guard";
import { ExternalPatientRoleGuard } from "../../shared/authguards/external-patient-role.guard";

export const QUESTION_ROUTES: Routes = [
  {
    path: "question",
    loadComponent: () => import("./question.component").then(m => m.QuestionComponent),
    canActivate: [AuthGuard, ExternalPatientRoleGuard],
    data: { title: "pageTitles.question" },
    children: [
      {
        path: "",
        loadComponent: () => import("./question-info/question-info.component").then(m => m.QuestionInfoComponent),
        data: { title: "pageTitles.question" },
      },
      {
        path: "info",
        loadComponent: () => import("./question-header/question-header.component").then(m => m.QuestionHeaderComponent),
        data: { title: "pageTitles.questionInfo" },
      },
      {
        path: "review",
        loadComponent: () => import("./question-review/question-review.component").then(m => m.QuestionReviewComponent),
        data: { title: "pageTitles.questionReview" },
      },
      {
        path: "message",
        loadComponent: () => import("../message/message.component").then(m => m.MessageComponent),
        data: { title: "pageTitles.questionMessages" },
      },
    ],
  },
];
