<div>
  <a (focus)="onSkipLinkFocus()"
     (focusout)="onSkipLinkFocusOut()"
     appSkipLink
     class="skip-link"
     tabindex="0">
    {{ 'accessibility.skipToContent' | translate }}
  </a>
</div>




