import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { SessionContext } from "../../models/session-context.model";
import { Observable, Subscription } from "rxjs";
import { UserInfoService } from "../../services/user-info.service";
import { PatientRole } from "../../models/patient-role.model";

@Injectable({
  providedIn: "root",
})

export class ExternalPatientRoleGuard {

  sessionContext: SessionContext;
  private subs: Subscription[] = [];

  constructor(
    public router: Router,
    public userService: UserInfoService,
  ) {
    this.subs.push(
      this.userService.sessionContext.subscribe((context: SessionContext) => {
        this.sessionContext = context;
      }),
    );
  }

  canActivate: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    const requestedUrl = state.url;
    if (this.sessionContext && this.sessionContext.patientRole) {
      const allowedPagesForExternal = [
        "/",
        "/settings",
        "/settings/contact-info",
        "/login",
        "/cross-communication",
        "/institution-list",
        "/info",
        "/review",
        "/cross-communication/institution-list",
        "/message",
      ];

      if (
        this.sessionContext.patientRole === PatientRole.EXTERNAL &&
        !allowedPagesForExternal.includes(requestedUrl)
      ) {
        this.router.navigate(["/"]);
        return false;
      }
    }
    return true;
  };

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
