<div [@fadeAndMove]="'in'" class="mobile-hamburger pt-5 d-flex; pe-4">
  <div *ngFor="let item of menuItems;">
    <button (click)="doClickAction(item.key)"
            (keydown.enter)="doClickAction(item.key)"
            *ngIf="!item.isHidden"
            [@fadeAndMove]="'in'"
            [attr.aria-label]="item.description === 'shared.messages' ?
         (areNewMessages ? (('shared.messages' | translate) + ('accessibility.newMessage' | translate)) : ('shared.messages' | translate)) : item.description | translate"
            class="align-items-center item-icon-section clear-button">
      <div [class.active]="item.isSelected"
           class="mobile-hamburger-item mobile-hamburger-text">
        <span class="description-text">{{ item.description | translate }}</span>
      </div>
      <div [class.active]="item.isSelected"
           class="mobile-hamburger-item circle-icon">
        <img *ngIf="!showLang(item.key); else langName" [alt]="item.key"
             [src]="item.iconLink"
             class="Messages-dot"
             loading="lazy" />
        <ng-template #langName>
          <div class="lang-icon">
            {{ (item.key === 'language' ? currentLanguage : item.key) | uppercase }}
          </div>
        </ng-template>
      </div>
    </button>
  </div>
</div>
