import { Injectable } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

@Injectable({
  providedIn: "root",
})
export class IconService {

  private path: string = "assets/scalableVectorGraphics";


  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
  }

  registerIcons(): void {
    // Here you register all your icons
    const icons = [
      { name: "checked", path: `${this.path}/checked.svg` },
      { name: "nutrition", path: `${this.path}/nutrition.svg` },
      { name: "mood", path: `${this.path}/disease/symptoms/mood.svg` },
      { name: "bmi", path: `${this.path}/disease/symptoms/measurements.svg` },
      { name: "medication", path: `${this.path}/main/prescriptions.svg` },
      { name: "exclamation", path: `${this.path}/main/exclamation-circle.svg` },
      { name: "sleep", path: `${this.path}/health-plan-sleep.svg` },
      { name: "checked-fitbit", path: `${this.path}/prescriptions/checked-fitbit.svg` },
      { name: "unchecked", path: `${this.path}/unchecked.svg` },
      { name: "newVisit", path: `${this.path}/appointments/Newvisit.svg` },
      { name: "low-activity", path: `${this.path}/motivationQuestionnaire/1.svg` },
      { name: "high-activity", path: `${this.path}/motivationQuestionnaire/4.svg` },
      { name: "blood-pressure", path: `${this.path}/disease/symptoms/blood-pressure.svg` },
      { name: "asthma", path: `${this.path}/asthma.svg` },
      { name: "blood-sugar", path: `${this.path}/disease/symptoms/blood-sugar.svg` },
      { name: "measurements", path: `${this.path}/disease/symptoms/measurements.svg` },
      { name: "connected-devices-true", path: `${this.path}/connected-devices-on.svg` },
      { name: "connected-devices-false", path: `${this.path}/connected_devices.svg` },
      { name: "close", path: `${this.path}/close.svg` },
      { name: "close_x", path: `${this.path}/close_x.svg` },
      { name: "small-habits", path: `${this.path}/chartIcons/small-habits.svg` },
      { name: "small-high-physical", path: `${this.path}/chartIcons/small-high-physical.svg` },
      { name: "small-low-physical", path: `${this.path}/chartIcons/small-low-physical.svg` },
      { name: "small-measurements", path: `${this.path}/chartIcons/small-measurements.svg` },
      { name: "small-medicines", path: `${this.path}/chartIcons/small-medicines.svg` },
      { name: "small-nutrition", path: `${this.path}/chartIcons/small-nutrition.svg` },
      { name: "small-sleep", path: `${this.path}/chartIcons/small-sleep.svg` },
      { name: "small-mood", path: `${this.path}/chartIcons/small-mood.svg` },
      { name: "trash-bin", path: `${this.path}/prescriptions/trashbin.svg` },
      { name: "more-info", path: `${this.path}/info-bold.svg` },
      { name: "error", path: `${this.path}/error.svg` },
      { name: "calendar", path: `${this.path}/calendar.svg` },
      { name: "desktop", path: `${this.path}/devices/desktop.svg` },
      { name: "mobile", path: `${this.path}/devices/mobile.svg` },
      { name: "send", path: `${this.path}/send.svg` },
    ];

    icons.forEach(icon => {
      try {
        this.matIconRegistry.addSvgIcon(
          icon.name,
          this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path),
        );
      } catch (error) {
        console.error(`Error registering icon named ${icon.name}:`, error);
      }
    });

  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
