import { Routes } from "@angular/router";
import { AuthGuard } from "../../shared/authguards/auth.guard";

export const VIDEO_ROUTES: Routes = [
  {
    path: "video",
    loadComponent: () => import("./video.component").then(m => m.VideoComponent),
    canActivate: [AuthGuard],
    data: { title: "pageTitles.video" },
    children: [
      {
        path: "",
        loadComponent: () => import("./video-list/video-list.component").then(m => m.VideoListComponent),
        data: { title: "pageTitles.video" },
      },
      {
        path: "info",
        loadComponent: () => import("./video-info/video-info.component").then(m => m.VideoInfoComponent),
        data: { title: "pageTitles.videoInfo" },
      },
    ],
  },
];
