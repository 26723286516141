<ng-container *ngIf="isMobile; else desktop">
  <!--when in health plan or root-->
  <ng-container *ngIf="isHealthPlan || isRoot && !isActivityReport && hasActivePlan">
    <button (click)="openSubMenu()" [attr.aria-label]="'healthPlan.youCanAddMeasurements' | translate"
            [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isHealthPlan || isRoot}"
            class="main-button"
            mat-fab>
      <mat-icon [ngClass]="{'rotate-close' : isSubMenuOpen, 'rotate-zero' : !isSubMenuOpen}" class="icon-color">add
      </mat-icon>
    </button>
  </ng-container>

  <!--when in health plan activity new-->
  <ng-container *ngIf="isActivityReport && hasActivePlan">
    <button (click)="saveAndGoBack()" [attr.aria-label]="'healthPlan.youCanAddMeasurements' | translate"
            [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isActivityReport}"
            class="main-button" mat-fab
            style="width: fit-content; border-radius: 20px;">
    <span style="padding: 5px 20px;
    display: inline-block;
    line-height: 24px;
    font-size: 20px;
    color: white;">{{ 'healthPlan.save' | translate }}</span>
    </button>
  </ng-container>

  <!--when in diary -->
  <ng-container *ngIf="isDiary">
    <button (click)="goToLocation('/new')"
            [attr.aria-label]="'diary.addNewMeasurement' | translate"
            [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isDiary}"
            class="main-button yellow-outline"
            mat-fab>
      <mat-icon class="icon-color">add</mat-icon>
    </button>
  </ng-container>
</ng-container>

<ng-template #desktop>
  <!--when in health plan or root-->
  <ng-container *ngIf="isHealthPlan || isRoot && !isActivityReport && hasActivePlan">
    <button (click)="openSubMenu()"
            [attr.aria-label]="'healthPlan.youCanAddMeasurements' | translate"
            [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isHealthPlan || isRoot}"
            [tooltip]="$any(fabBtnTooltip)"
            class="desktop-main-button"
            contentType="template"
            mat-fab>
      <mat-icon [ngClass]="{'rotate-close' : isSubMenuOpen, 'rotate-zero' : !isSubMenuOpen}"
                class="icon-color">add
      </mat-icon>
    </button>
  </ng-container>

  <!--when in health plan activity new-->
  <ng-container *ngIf="isActivityReport && hasActivePlan">
    <button (click)="saveAndGoBack()"
            [attr.aria-label]="'healthPlan.youCanAddMeasurements' | translate"
            [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isActivityReport}"
            class="desktop-main-button"
            mat-fab
            style="width: fit-content; border-radius: 20px;">
      <span style="padding: 5px 20px;
    display: inline-block;
    line-height: 24px;
    font-size: 20px;
    color: white;">{{ 'healthPlan.save' | translate }}</span>
    </button>
  </ng-container>

  <!--when in diary -->
  <ng-container *ngIf="isDiary">
    <button (click)="goToLocation('/new')"
            [attr.aria-label]="'diary.addNewMeasurement' | translate"
            [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isDiary}"
            [tooltip]="$any(fabBtnTooltipDiary)"
            class="desktop-main-button yellow-outline"
            contentType="template"
            mat-fab>
      <mat-icon class="icon-color">add</mat-icon>
    </button>
  </ng-container>
</ng-template>

<ng-template #fabBtnTooltip>
  <app-tooltip-info text="healthPlan.youCanAddMeasurements">
  </app-tooltip-info>
</ng-template>
<ng-template #fabBtnTooltipDiary>
  <app-tooltip-info text="diary.addNewMeasurement">
  </app-tooltip-info>
</ng-template>



