import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { DeviceDetectService } from "../../services/device-detect.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-leave-form",
  templateUrl: "./leave-form.component.html",
  styleUrls: ["./leave-form.component.css"],
  standalone: true,
  imports: [NgClass, MatDialogActions, MatDialogClose, TranslateModule],
})
export class LeaveFormComponent implements OnInit {
  result: boolean;
  isMobile: boolean;

  constructor(
    private dialogRef: MatDialogRef<LeaveFormComponent>,
    private deviceDetectService: DeviceDetectService,
  ) {
  }

  ngOnInit(): void {
    this.isMobile = this.deviceDetectService.isMobile;
  }

  onClose(value) {
    this.dialogRef.close(value);
  }

}
