import { Routes } from "@angular/router";
import { AuthGuard } from "../../shared/authguards/auth.guard";

export const TERMINATE_ROUTES: Routes = [
  {
    path: "terminate-leave",
    loadComponent: () => import("./terminate.component").then(m => m.TerminateComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadComponent: () => import("./terminate-info/terminate-info.component").then(m => m.TerminateInfoComponent),
        data: { title: "pageTitles.terminateLeave" },
      },
      {
        path: "review",
        loadComponent: () => import("./terminate-review/terminate-review.component").then(m => m.TerminateReviewComponent),
        data: { title: "pageTitles.terminateLeaveReview" },
      },
      {
        path: "info",
        loadComponent: () => import("./terminate-header/terminate-header.component").then(m => m.TerminateHeaderComponent),
        data: { title: "pageTitles.terminateLeaveInfo" },
      },
      {
        path: "message",
        loadComponent: () => import("../message/message.component").then(m => m.MessageComponent),
        data: { title: "pageTitles.terminateLeaveMessages" },
      },
    ],
  },
];
