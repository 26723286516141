import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { ModalService } from "../../services/modal.service";

@Injectable()
export class SessionContextInterceptor implements HttpInterceptor {

  constructor(private modalService: ModalService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes("get-session-context")) {
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            if (event.body?.isUnderage === true && event.body?.enableRestrictUnderageUsers !== null) {
              this.modalService.openDialog({
                type: "underage",
                restrictionDate: event.body.enableRestrictUnderageUsers,
              });
            }
          }
        }));
    }

    // If the request does not match 'get-session-context', just pass it through
    return next.handle(req);
  }
}
